.my-account-card {
    background: var(--white);
}

.my-account-card-userimage {
    height: 70px;
    background: var(--bg-gradient);
    position: relative;
    display: flex;
    justify-content: center;
}

    /*
        This is the CSS to be used for user image. Delete the => .my-account-card-userimage img .MuiSvgIcon-root 
        .my-account-card-userimage img {
        position: absolute;
        bottom: 0;
        left: calc(50% - 50px);
        top: calc(100% - 10px);
        border: 10px solid var(--white);
        border-radius: 50%;
    }*/
    .my-account-card-userimage img {
        position: absolute !important;

        top: calc(100% - 40px) !important;
        border-radius: 50%;
        height: 5rem;
        width: 5rem;
    }

.my-account-card-details {
    padding: 20px;
}

.my-account-card-details-name {
    font-size: 16px;
    padding-top: 0.5rem;
    margin-top: 13px;
    margin-bottom: 0;
}

.my-account-card-details-email {
    font-size: 14px;
    margin: 0;
    color: var(--dark-blue);
}

.my-account-card-details-department {
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 10px;
    color: var(--dark-blue);
}

.help-card {
    padding: 12px 15px;
    background: var(--white);
    border-radius: 5px;
}

.help-card-item {
    padding: 10px;
    border-radius: 5px;
    background: var(--light-sand);
    margin-bottom: 10px;
}

.help-card-item-text {
    color: var(--dark-blue);
    margin: 0 0 5px;
    text-decoration: underline;
    font-size: 14px;
}

.help-card-item-text-desc {
    margin: 0;
    color: var(--dark-blue);
    font-size: 12px;
}

.help-document-link {
    color: var(--light-blue);
    text-decoration: underline;
    font-weight: bold;
}

.click-rmt {
    margin-top: 2px;
    margin-bottom: 2px;
    color: var(--dark-blue);
}

.click-rmt-link {
    padding-left: 5px;
    color: var(--light-blue);
}

.intra-block {
    position: fixed;
    left: calc(100vw - 45%);
}

.intra-text {
    font-size: 18px;
}

/*Do not remove this ID css*/
#primary-search-account-menu-mobile ul {
    background: var(--white) !important;
}

#mobileMenuIcon span svg {
    background: none;
}

.header-icon {
    padding: 12px 0 !important;
}

#primary-search-account-menu > div:nth-child(3), #primary-help-menu > div:nth-child(3) {
    top: 70px !important;
    outline: 0;
    transform-origin: 355px 70px !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .intra-block {
        display: none !important;
    }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
    .intra-block {
        left: calc(100vw - 24%);
    }
}