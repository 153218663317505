.pt-divider {
    padding: 8px 0;

    hr {
        border-top: 3px dashed var(--light-grey);
        border-bottom: none;
    }
}
.vl {
    border-left: 3px dashed var(--light-grey);
  }
.text-center {
    text-align: center;
}
.fw-bold {
    font-weight: bold;
}
.ml-2 {
    margin-left: 2rem;
}
.mb-8e {
    margin-bottom: 8em;
}
.mb-7e {
    margin-bottom: 7em;
}