.pt-button--acknowledge {
  margin-top: 20px;
}

.QR-Code-details {
  border: 2px solid var(--light-blue);
  border-radius: 5px;

  div {
    padding: 5px 0;
    border-bottom: 1px dashed var(--light-blue);
  }
}

.QR-Code-block {
  .QR-Code {
    margin: 10px 0;
  }
}

.ackFooter {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 16px;
}

.gridCenter {
  text-align: center;
}

.po-success-block {
  margin-top: 20px;
  padding: 10px 40px;

  .success-po {
    height: 60px;
    width: 60px;
  }
}

.MuiInputAdornment-filled > .MuiButtonBase-root {
  background-color: var(--transparent);
  border-radius: 0;
}

.date-grid-item > div {
  margin: 0;
}

#dataGridItem .MuiFilledInput-adornedEnd {
  padding-right: 0;
  background-color: var(--input-bg-color);

  &:hover {
    background-color: var(--input-bg-color-focus);
  }
}

#dispatchDate {
  margin-right: -12px !important;
}

.ackFooter > button.Mui-disabled {
  background: var(--light-sand);
  color: var(--input-text-color-disabled);
  border: 1px solid var(--dark-sand);
}
.btn-div-dispatch-order-margin {
  margin: 12px;
}
.btn-div-dispatch-order-margin > button.Mui-disabled {
  background: var(--light-sand);
  color: var(--input-text-color-disabled);
  border: 1px solid var(--dark-sand);
}
