.bulk-upload-polist-block {
  padding-bottom: 40px;

  .MuiSvgIcon-root {
    background: transparent !important;
  }

  .bulk-upload-sample-template {
    display: flex;
    justify-content: space-between;

    @media screen and (min-width: 365px) and (max-width: 1024px) {
      flex-direction: column;
    }

    .bulk-upload-form {
      .bulk-upload {
        margin-right: 24px;

        label {
          transform: translate(12px, 10px) scale(0.75);
        }
      }
    }
  }
}
.ol-bulkUpload-Error {
  font-size: 14px;
  font-weight: bold;
}
.ul-bulkUpload-Error {
  font-size: 14px;
  background: none;
  font-weight: normal;
}
