.pd-2 {
  padding: 2em;
}
.pl-2 {
  padding-left: 2em;
}
.my-1 {
  margin-top: 1em;
  margin-bottom: 1em;
}
.scanner-table,
.scanner-row,
.scanner-td {
  border: 1px solid #00bedc;
}
.width-100 {
  width: 100%;
}
.width-50 {
  width: 50%;
}
.qr-tables {
  display: flex;
  overflow: auto;
  white-space: nowrap;
}
.scanner-table th,
.scanner-td {
  padding: 0.5em 1em;
}
.scanner-table {
  margin-right: 20px;
  margin-bottom: 1em;
}
.mb-1 {
  margin-bottom: 1rem;
}
.mt-1 {
  margin-top: 1rem;
}
.pd-1 {
  padding: 1rem;
}
.ul-no-bg{
  background: none !important;
}