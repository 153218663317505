.reprintSubmitQR {
  margin-left: 25px;
}

.MuiSwitch-root {
  .MuiIconButton-root:hover {
    padding: 9px !important;
  }
}

.MuiTypography-body1 {
  margin-top: 4% !important;
}
