@import "../../../src/assets/colors.css";

.dispatch-remark-icon {
  font-size: 3.5rem !important;
}
.MuiSvgIcon-fontSizeLarge {
  font-size: 3.5rem !important;
}
.MuiTableCell-alignCenter {
  padding-right: 60px !important;
}
