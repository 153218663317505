$mobile: 320px;
$mediumTablet: 767px;
$largeTablet: 1024px;
$desktop: 1399px;

.bg-login-register {
    background: url("../../assets/images/LoginPageBgImage.png");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    min-height:110vh;
    overflow: auto;

    .pt-login-logo-block {
        padding: 24px 0 8px;
        
        @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
            text-align: center;
        }

        .official-logo {
            width: 160px;
        }
    }

    .pt-lr-mobileContainer  {
        padding: 0px 80px;
        justify-content: center;

        @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
            padding: 0px 20px;
        }

        .pt-lr--project-block {      
            display: flex;
            flex-direction: column;

            .pt-lr--project {
                padding: 0 24px;
                margin-top: 40px;
                margin-bottom: 40px;
                width: 80%;
                border-width: 0px;
                border-left: 10px solid;
                border-image: linear-gradient(to bottom, var(--light-green), var(--light-green--2)) 1 100%;

                @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
                    width: 100%;
                    padding: 0 48px 0 24px;
                    margin-left: 0px;
                }

                .pt-lr-project-name {
                    font-family: "SiemensSans-Black", sans-serif;
                    font-size: 2rem;
                    line-height: 40px;
                    color: var(--dark-blue);
                    margin: 0;

                    @media screen and (min-width: $mobile) and (max-width: $mediumTablet) {
                        font-size: 1.5rem;
                        line-height: 30px;
                    }
                }
                 
                .pt-lr--project-description {
                    font-size: 1.125rem;
                    display: block;
                    margin: 16px 0 0;    
                }
            }
        }

        .pt-lr-master-block {

            .pt-lr-block {
                padding: 24px 40px;
                box-shadow: 0 0px 10px var(--light-grey);
                background-color: var(--white);
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;

                @media screen and (min-width: $mobile) and (max-width: $largeTablet) {
                    margin: 40px 0;
                }
    
                .pt-login-siemens_user {
    
                    h3 {
                        margin: 0;
                    }

                    .myid-login-btn-block {
                        margin: 16px 0 0;
                    }
                }
    
                .pt-divider {
                    padding: 8px 0;

                    hr {
                        border-top: 3px dashed var(--light-grey);
                        border-bottom: none;
                    }
                }
    
                .pt-login-external_user {
                    
                    h3 { 
                        margin: 0
                    }

                    .pt-login-form {
                        margin: 16px 0 0;

                        .pt-login-form-input {
                            width: 100%;
                            margin-bottom: 16px;
                        }
                    }
                }
            }
        }
    }   
}

.displayedTime {
    margin-top: 10px;   
}

.count, .split {
    background-color: #ebf7f8;
    padding: 8px;
}
.forget-pwd-cursor{
    cursor: pointer;
    margin-bottom: 0;
}
.forget-pwd-cursor:hover{
    color: var(--blue-green);
}
.heading-margin-5tb-0lr{
    margin:5px 0px;
}
.pl-tab-2i{
    padding-left: 2em !important;
}
.pr-tab-2i{
    padding-right: 2em !important;
}
.contact-us-text {
    font-style: italic;
    color: var(--petrol-green);
    padding-bottom: 1em;
}